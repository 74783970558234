@import "compiled/svg";
@import "global/fonts";

$color_1: #393939;
$color_2: inherit;
$color_3: #fff;
$color_4: #757575;
$font-family: brandon grotesque, sans-serif;
$background-color_1: #fff;
$background-color_2: #ababab;
$background-color_3: #000;
$background-color_4: transparent;
$background-color_5: #f8f3f2;

.clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.form-row {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    margin: 0 0 1.25rem;
}

.top-banner {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: relative;
    padding: 1.25rem 0.625rem 0.625rem;

    * {
        box-sizing: border-box;
    }
}

nav[role="navigation"] {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: absolute;
    top: 0;
    left: -90%;
    width: 90%;
    margin-top: 0;
    background-color: $background-color_1;
    z-index: 2;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;

    li {
        float: left;
        list-style: none !important;
    }
}

.icon.svg-account-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-back-to-top-icon-dims {
    width: 36px;
    height: 36px;
}

.icon.svg-cart-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-email-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-search-dims {
    width: 20px;
    height: 20px;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    fill: #393939;
}

.icon.header-icons {
    fill: #393939;

    &:hover {
        fill: #393939;
    }
}

.icon.hoverable {
    &:hover {
        fill: #393939;
    }
}

.icon.close-icon {
    fill: #757575;
}

.icon.collapse {
    fill: #757575;
}

.icon.email-icon {
    fill: #757575;
}

.icon.expand {
    fill: #757575;
}

.icon.arrow {
    fill: #000;
}

.icon.arrow-down {
    fill: #000;
}

.icon.arrow-up {
    fill: #000;
}

.icon.arrow.right {
    transform: rotate(180deg);
}

.icon.arrow.down {
    transform: rotate(-90deg);
}

html {
    font-family: $font-family;
    text-size-adjust: 100%;

    input[type="button"] {
        appearance: button;
        cursor: pointer;
    }
}

body {
    margin: 0;
    overflow-x: hidden;
    background: 0 0 #fff;
    font-family: $font-family;
    font-size: 16px;
    font-size: 1rem;
    letter-spacing: 0.1px;
    color: $color_1;
    -moz-osx-font-smoothing: grayscale;
}

article {
    display: block;
}

aside {
    display: block;
}

details {
    display: block;
}

figcaption {
    display: block;
}

figure {
    display: block;
}

footer {
    display: block;
    background-color: $background-color_1;
    clear: both;
}

header {
    display: block;
}

hgroup {
    display: block;
}

main {
    display: block;
}

nav {
    display: block;
}

section {
    display: block;
}

summary {
    display: block;
}

a {
    background: 0 0;
    color: $color_2;
    text-decoration: inherit;

    &:active {
        outline: 0;
    }

    &:hover {
        outline: 0;
    }
}

b,
strong {
    font-family: $font-family;
    font-weight: 700;
}

img {
    border: 0;
    max-width: 100vw;
}

svg {
    &:not(:root) {
        overflow: hidden;
    }

    use {
        pointer-events: none;
    }
}

hr {
    box-sizing: content-box;
    border: 0;
    height: 1px;
    background-color: $background-color_2;
}

button {
    font: inherit;
    margin: 0;
    overflow: visible;
    appearance: button;
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family;
    font-weight: 700;
    font-size: 17px;
    font-size: 1.0625rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #000;
    background-color: $background-color_3;
    color: $color_3;
    display: inline-block;
}

input {
    font: inherit;
    margin: 0;
    color: $color_2;
    line-height: normal;

    &::-webkit-file-upload-button {
        border-radius: 0;
    }
}

optgroup {
    color: $color_2;
    font: inherit;
    margin: 0;
}

select {
    font: inherit;
    margin: 0;
    color: $color_2;
    text-transform: none;
}

textarea {
    font: inherit;
    margin: 0;
    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;

    &::input-placeholder {
        color: $color_4;
    }
}

input[type="date"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="email"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="number"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="password"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="tel"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="text"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 16px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid #ababab;
    border-radius: 4px;
    text-indent: 0;
    background: #fff;
    color: $color_1;
}

input[type="reset"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

input[type="submit"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

input[type="button"] {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family;
    font-weight: 700;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #000;
    background-color: $background-color_3;
    color: $color_3;
}

input[type="file"] {
    border-radius: 0;
}

html.menu-active {
    overflow-x: hidden;
}

.p {
    line-height: 1.43;

    a {
        overflow: hidden;
        color: $color_1;
        text-decoration: underline;
        line-height: inherit;
        font-family: $font-family;
        font-weight: 700;
    }
}

p {
    line-height: 1.43;

    a {
        overflow: hidden;
        color: $color_1;
        text-decoration: underline;
        line-height: inherit;
        font-family: $font-family;
        font-weight: 700;
    }
}

ol {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

ul {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

.button {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family;
    font-weight: 700;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #000;
    background-color: $background-color_3;
    color: $color_3;
}

.buttonstyle {
    line-height: 1em;
    padding: 0.625rem 8%;
    min-height: 2.5rem;
    font-family: $font-family;
    font-weight: 700;
    font-size: 17px;
    font-size: 1.0625rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #000;
    background-color: $background-color_3;
    color: $color_3;
}

.button-fancy-large {
    display: inline-block;
}

.menu-category {
    a {
        font-size: 16px;
        font-size: 1rem;
        display: block;
        color: $color_1;
        font-family: $font-family;
        text-decoration: none;
    }

    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;

    li {
        list-style: none;
        padding-left: 0;
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;
    }
}

.mobile-menu-utility-user {
    a {
        font-size: 16px;
        font-size: 1rem;
        display: block;
        color: $color_1;
        font-family: $font-family;
        text-decoration: none;
    }

    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    display: block;
    list-style: none;

    li {
        list-style: none;
        padding-left: 0;
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;
    }
}

label {
    font-size: 16px;
    font-size: 1rem;
    display: block;
    color: $color_1;
    font-family: $font-family;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 0.4375rem;
}

.char-count {
    line-height: 2em;
    font-size: 12px;
    font-size: 0.75rem;
    color: $color_1;
}

.form-caption {
    line-height: 2em;
    font-size: 12px;
    font-size: 0.75rem;
    color: $color_1;
    clear: left;
    width: auto;
}

#wrapper {
    position: relative;
    left: 0;
    transition: left 0.2s ease-in;
    width: 100%;
    border: 0;
    margin: auto;
    background-color: $background-color_1;
}

#main {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;
    padding: 1.5625rem 0;
}

.account-menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-size: 1.1em;
    background-color: $background-color_4;
    color: $color_1;
    margin: 0;
}

.menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-size: 1.1em;
    background-color: $background-color_4;
    color: $color_1;
    margin: 0 3% 0 0.625rem;
}

.mobile-cap {
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    text-align: center;
}

.mobile-menu-level-label {
    display: none;
    font-family: $font-family;
    font-weight: 700;
    font-size: 17px;
    font-size: 1.0625rem;
    letter-spacing: 0;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: $background-color_5;
    line-height: 1rem;
}

.level-1 {
    >li {
        a {
            display: block;

            .icon {
                margin-right: 6px;
            }
        }

        background-color: $background-color_4;

        &:first-child {
            border: 0;
        }

        >a {
            color: $color_1;
        }
    }
}

.mobile-menu-level-label.show-menu-item {
    display: block;
}

.menu-utility-user {
    li {
        &:first-child {
            border: 0;
        }

        list-style: none;
        padding: 0.8em 1rem;
        border-top: 1px solid #393939;

        a {
            text-decoration: none;
            color: $color_3;
        }
    }

    display: none;
    margin: 0;
    padding: 0;

    .user-info {
        .user-panel {
            transition: all 0.3s ease;
            width: 290px;
            box-sizing: border-box;
            text-align: left;
            top: 40px;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            right: -1.5rem;
            z-index: 20;
            margin-top: 0;
            background-color: $background-color_1;
            box-shadow: 0 2px 9px 0 #ababab;

            h3 {
                padding: 0;
                margin: 0.625rem 0 1.125rem;
                text-transform: capitalize;
            }

            h6 {
                padding: 0;
                margin: 0.625rem 0 1.125rem;
                text-transform: capitalize;
                line-height: 1rem;
            }

            .user-panel-wrapper {
                position: relative;
                padding: 1.25rem;
                box-sizing: border-box;

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    transform: rotate(45deg);
                    top: -8px;
                    right: 24px;
                    box-shadow: -4px -4px 20px -5px rgb(0 0 0 / 25%);
                    z-index: 100;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 40px;
                    top: -20px;
                    background: 0 0;
                }
            }

            .user-messages {
                .p {
                    margin-bottom: 1.125rem;
                    margin-top: 0;

                    a {
                        line-height: 1rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                p {
                    margin-bottom: 1.125rem;
                    margin-top: 0;

                    a {
                        line-height: 1rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .user-links {
                a {
                    &:last-child {
                        margin-bottom: 0;
                    }

                    line-height: 1em;
                    padding: 0.625rem 8%;
                    min-height: 2.5rem;
                    font-family: $font-family;
                    font-weight: 700;
                    font-size: 17px;
                    font-size: 1.0625rem;
                    display: inline-block;
                    transition: all 0.3s ease;
                    box-sizing: border-box;
                    border-radius: 40px;
                    letter-spacing: 0.2px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: none;
                    cursor: pointer;
                    border: 2px solid #000;
                    background-color: $background-color_3;
                    color: $color_3;
                    margin-bottom: 0.625rem;
                    width: 100%;
                }

                padding-left: 0;
                margin-top: 0.625rem;
                padding-top: 0.625rem;
                border-top: 1px solid #ababab;
            }

            a {
                display: block;
                padding: 0.5rem 0;
                color: $color_1;
            }

            .user-logout {
                text-decoration: underline;
            }
        }
    }
}

#mini-cart {
    .mini-cart-name {
        a {
            color: $color_1;
        }
    }

    position: absolute;
    top: 21px;
    right: 20px;
    z-index: 20;
}

.mini-cart-total {
    .mini-cart-link {
        color: $color_1;
    }

    .mini-cart-empty {
        transition: color 0.5s ease;
        color: $color_1;
    }
}

.mini-cart-content {
    display: none;
}

.mini-cart-content-modal {
    display: none;
}

.skip-nav {
    position: absolute;
    left: -10000px;
}

.header-search {
    position: relative;
    z-index: 1;
    height: 40px;
    clear: both;
    width: 100%;
    border: 1px solid #ababab;
    border-radius: 4px;
    margin: 1.125rem auto 0;
    background-color: $background-color_1;
    color: $color_4;

    input[type="text"] {
        float: left;
        width: calc(100% - 2.5rem);
        height: 40px;
        padding-left: 16px;
        margin: 0;
        background-color: $background-color_4;
        border: 0;
    }

    button {
        padding: 0.25rem 0.6rem;
        border: 0;
        background-color: $background-color_4;
        color: $color_1;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.primary-logo {
    position: relative;
    z-index: 1;
    height: 40px;
    width: 100px;
    background: url("../images/svg-icons/logo-mobile.svg") no-repeat;
    background-size: contain;
    margin: 0 auto;
    top: 3px;

    a {
        display: block;
        height: 100%;
    }
}

.h3 {
    font-family: $font-family;
    font-weight: 700;
    font-size: 21px;
    font-size: 1.3125rem;
    letter-spacing: 0;
}

h3 {
    font-family: $font-family;
    font-weight: 700;
    font-size: 21px;
    font-size: 1.3125rem;
    letter-spacing: 0;
}

.h4 {
    letter-spacing: 0;
    font-family: $font-family;
    font-size: 19px;
    font-size: 1.1875rem;
}

.h5 {
    letter-spacing: 0;
    font-family: $font-family;
    font-weight: 700;
}

h4 {
    letter-spacing: 0;
    font-family: $font-family;
    font-size: 19px;
    font-size: 1.1875rem;
}

h5 {
    letter-spacing: 0;
    font-family: $font-family;
    font-weight: 700;
}

.footer-container {
    .footer-item {
        h3 {
            font-family: $font-family;
            font-weight: 700;
            letter-spacing: 0;
            font-size: 16px;
            font-size: 1rem;
            position: relative;
            padding: 0 0.625rem;
            margin: 0;
            line-height: 46px;
            line-height: 2.875rem;
        }

        display: block;
        width: 100%;
        border-bottom: 1px solid #ababab;
        box-sizing: border-box;

        h3.toggle {
            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 1rem;
                top: 0.9375rem;
                width: 1rem;
                height: 1rem;
                background: url("../images/svg-icons/increase.svg") no-repeat 50%;
            }
        }

        h4 {
            margin: 0;
        }

        .note {
            margin-bottom: 0.625rem;
        }
    }

    .footer-item.social-item {
        .call-us {
            a {
                font-family: $font-family;
                font-weight: 700;
                letter-spacing: 0;
                font-size: 16px;
                font-size: 1rem;
                font-size: 17px;
                font-size: 1.0625rem;
            }

            line-height: 24px;
            line-height: 1.5rem;
            margin-top: 1.25rem;
        }

        border: 0;
        margin-bottom: 1.25rem;
        padding: 0 0.625rem;

        a {
            line-height: 19px;
            line-height: 1.1875rem;
        }
    }

    color: $color_1;
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;

    .footer-item.email-item {
        padding: 0.75rem 0;

        h4 {
            line-height: 26px;
            line-height: 1.625rem;
        }
    }

    .menu-footer {
        li {
            line-height: 19px;
            line-height: 1.1875rem;
            float: none;
            list-style: none;
            margin-bottom: 0.8125rem;
            padding-left: 0.625rem;
        }

        float: none;
    }

    a {
        color: $color_1;
        text-decoration: none;
    }
}

.h6 {
    font-family: $font-family;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 16px;
    font-size: 1rem;
}

h6 {
    font-family: $font-family;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 16px;
    font-size: 1rem;
}

.copyright {
    text-align: center;
    background: #f8f3f2;
    color: $color_1;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2.3333em;

    .copyright-container {
        .copy-links {
            a {
                margin: 0 0.625rem;
                color: $color_1;
                text-decoration: none;
            }
        }
    }
}

.back-to-top {
    visibility: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    .back-to-top-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
    }
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

[class^="ui-"] {
    box-sizing: content-box;

    &::after {
        box-sizing: content-box;
    }

    &::before {
        box-sizing: content-box;
    }
}

#ModalViewDialog {
    color: $color_1;
}

.pt_storefront {
    #main {
        width: 100%;
        max-width: 100%;
        padding: 0 0 3.75rem;
    }
}

.mobile-category-buttons {
    text-align: center;
    box-sizing: border-box;
    padding: 0 1.25rem;
    margin-top: 1.875rem;
}

.home-bottom-second {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #f3f3f3), color-stop(58%, #fff));
    background: linear-gradient(180deg, #f3f3f3 25%, #fff 58%);
    background-position-y: 60px;
}

.br-s {
    display: block;
}
